import { get, writable, readable } from 'svelte/store';
import { audio } from './audio';
import { add } from './playlist';
import { current, src } from './track';

// is playing ?
export const playing = writable( false );


// do play current track
const doPlay = function( track ) {
	audio.play().then( function() {
		
		playing.set(true);

	});
}


// on current track change
current.subscribe( function( track ) {

	// update audio src
	audio.src = track ? track.src : '';
	audio.currentTime = 0;
	audio.load();

	// add track to play history
	add( track );
});




// player is in random mode
export const random = writable( false );

// play track, or pause if already playing
export const play = function( track, force ) {

	// check validity [MELCHIOR-C](https://go-on-web.sentry.io/issues/2492913/)
	if( !audio || !audio.src || !track || !track.src ) {
		return;
	}


	// define a new track ?
	if( audio.src != track.src ) {
		current.set( track );
		force = true;
	}

	// just toggle pause or force playing
	pause( force );
}


export const playRandom = function() {
  // fetch track from window.vocab.url.random and play it
  fetch( window.vocab.url.random )
    .then( function( response ) {
      return response.json();
    })
    .then( function( track ) {
      play( track, true );
    });
}


// toggle pause state
export const pause = function( force ) {
	
	// was not playing or force => start playing
	if( !get( playing ) || force ) {
		audio.play().then( function() {
			playing.set(true);
		});
	}

	// else => pause
	else {
		audio.pause();
		playing.set(false);
	}
}


// stop track
export const stop = function() {

	// stop audio playing
	audio.pause();

	// set current track to null
	current.set( null );
}




// // update time while playing
// audio.addEventListener("timeupdate",function() { 
// 	time.set( audio.currentTime );
// });

// // update volume on volume change
// audio.addEventListener('volumechange', function() {
// 	volume.set( audio.volume );
// 	muted.set( audio.muted );
// });

// audio.addEventListener("canplay",function(){
// 	ready.set(true);

// 	if( !get(paused) ){
// 		audio.play();
// 	}
// });

// audio.addEventListener('emptied',function() {
// 	ready.set(false);
// });

// audio.addEventListener('pause',function() {
// 	paused.set(true);
// });


// // when audio playing ended
// audio.addEventListener("ended",function() {

// 	// play next if exists in playlist
// 	let track = next( get(playing) );
// 	if( track ) {
// 		play( track, true );
// 	}
// 	else {
// 		stop();
// 	}
// });
